import React, { useRef, useState } from "react"
import loadable from "@loadable/component"
// import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import VideoPlay from "../../core/VideoPlay"
import H2 from "../../core/H2"
const Slider = loadable(() => import("react-slick"))

export default function TestmonialNewSlider({ testimonials, title }) {

    const [isOpen, setIsOpen] = useState(false)
    const [videoUrl, setVideoUrl] = useState(null)

    const handleVideo = e => {
        document.body.classList.add("overflow-hidden")
        setIsOpen(true)
        setVideoUrl(e)
    }

    const handleCloseVideo = () => {
        setIsOpen(false)
        document.body.classList.remove("overflow-hidden")
    }
    const customeSlider = useRef(null);
    const previous = () => {
        customeSlider.current.slickPrev();
    };
    const next = () => {
        customeSlider.current.slickNext();
    };
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        arrows: false,
    }

    const sortedTestimonials = testimonials && testimonials?.sort((a, b) => {
        if (a?.TestimonialsCPT?.testimonialOrder && b?.TestimonialsCPT?.testimonialOrder) {
          return a?.TestimonialsCPT?.testimonialOrder - b?.TestimonialsCPT?.testimonialOrder;
        } else if (a?.TestimonialsCPT?.testimonialOrder) {
          return -1; 
        } else if (b?.TestimonialsCPT?.testimonialOrder) {
          return 1; 
        } else {
          return 0;
        }
      })
    // https://www.youtube.com/embed/500XZL2qCFg

    const onClickClutchImg = () => {        
        const linkUrl =  "https://clutch.co/profile/webmob-technologies#summary";    
        window.open(linkUrl, "_self");      
    }

    return (
        <>
            {isOpen && (
                <VideoPlay videoUrl={videoUrl} handleCloseVideo={handleCloseVideo} />
            )}

            <div className="slider-container " data-aos="fade-up">
                <div className="bg-transperent overflow-hidden">
                    {title && (
                        <div className='text-center flex justify-center pt-20 pb-16'>
                            <H2
                                title={title}
                                className={`max-w-5xl`}
                            />
                        </div>
                    )}
                    <Slider
                        {...settings}
                        ref={customeSlider}
                        className="custom-slick-slider slider slider__container mx-auto"
                    >
                        {sortedTestimonials && (sortedTestimonials?.map(({ TestimonialsCPT }, index) => {
                            return (
                                <>
                                    {/* <div
                                    className="relative"
                                    key={Math.random()}
                                > */}
                                <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8" key={Math.random()}>
                                        <div className="relative max-w-4xl mx-auto">
                                            <div className="flex-shrink-0 md:block md:absolute relative xl:-left-32 left-0">
                                                <img
                                                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/06/Quotes.png"
                                                    className="h-12 w-auto"
                                                    alt="Quotes.png"
                                                    width={600}
                                                    height={600}
                                                    title="Quotes"
                                                    loading="lazy"
                                                />
                                            </div>
                                            <div className="md:max-w-lg lg:max-w-2xl xl:max-w-full mx-auto">
                                                <div className="text-left md:text-2xl text-xl mx-auto  mt-5">
                                                    {TestimonialsCPT?.review && <p className="leading-10 font-semibold text-shark-500">{TestimonialsCPT?.review}</p>}
                                                </div>

                                                <div className="flex w-full h-full items-center lg:mt-12 mt-10">
                                                    <div className="flex-shrink-0">
                                                        {TestimonialsCPT?.videoLink ?
                                                           <>
                                                             <div role="button" className="relative md:inline-block" onClick={() => handleVideo(TestimonialsCPT?.videoLink)} aria-hidden="true">
                                                             <img
                                                                    className="h-20 w-20 mx-auto rounded-full cursor-pointer relative"
                                                                    src={TestimonialsCPT?.avatar?.mediaItemUrl}
                                                                    alt={TestimonialsCPT?.avatar?.altText ? TestimonialsCPT?.avatar?.altText : "Testimonial"}
                                                                    width={TestimonialsCPT?.avatar?.mediaDetails?.width}
                                                                    height={TestimonialsCPT?.avatar?.mediaDetails?.height}
                                                                    title="Testimonial"
                                                                    loading="lazy"
                                                                    // onClick={() => handleVideo(TestimonialsCPT?.videoLink)}
                                                                />
                                                                <img
                                                                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/05/Play-Button.png"
                                                                    alt="Play-Button"
                                                                    width="80px"
                                                                    height="80px"
                                                                    title="Play-Button"
                                                                    className="absolute -bottom-1 -right-1 rounded-full w-8 cursor-pointer"
                                                                    loading="lazy"
                                                                    // onClick={() => handleVideo(TestimonialsCPT?.videoLink)}
                                                                />
                                                            </div> 
                                                           </>
                                                            :
                                                            <img
                                                                className="h-20 w-20 rounded-full mx-auto"
                                                                src={TestimonialsCPT?.avatar?.mediaItemUrl}
                                                                alt={TestimonialsCPT?.avatar?.altText ? TestimonialsCPT?.avatar?.altText : "Testimonial"}
                                                                width={TestimonialsCPT?.avatar?.mediaDetails?.width}
                                                                height={TestimonialsCPT?.avatar?.mediaDetails?.height}
                                                                title="Testimonial"
                                                                loading="lazy"
                                                            />
                                                        }
                                                    </div>
                                                    <div className=" flex justify-center flex-col text-left ml-6 md:mt-0 mt-3">
                                                        {TestimonialsCPT?.name && <div className="text-xl leading-6 font-medium text-shark-500 ">
                                                            {TestimonialsCPT?.name}
                                                        </div>}
                                                        {TestimonialsCPT?.positionAndCompany && <div className="mt-2 max-w-sm text-sm font-medium text-shark-200">
                                                            {TestimonialsCPT?.positionAndCompany}
                                                        </div>}
                                                    </div>
                                                    <div className="h-14 ml-6 py-1 md:flex flex-shrink-0 border-l border-shark-50 hidden" id={`clutch-${index}-desktop`}>
                                                        {/* <a href="https://clutch.co/profile/webmob-technologies#summary" className="w-auto"> */}
                                                            <img
                                                                className="h-full py-3 w-auto pl-6 cursor-pointer"
                                                                alt={`clutch-${index}-desktop`}
                                                                src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/05/Clutch-Logo.png"
                                                                height={228}
                                                                width={700}
                                                                loading="lazy"
                                                                onClick={onClickClutchImg}
                                                            />
                                                        {/* </a> */}
                                                    </div>
                                                </div>
                                                <div className="h-8 mt-5 flex flex-shrink-0 md:hidden" id={`clutch-${index}-mobile`}>
                                                    <a href="https://clutch.co/profile/webmob-technologies#summary">
                                                        <img
                                                            className="h-full w-auto "
                                                            alt={`clutch-${index}-mobile`}
                                                            src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/05/Clutch-Logo.png"
                                                            height={228}
                                                            width={700}
                                                            loading="lazy"
                                                            onClick={onClickClutchImg}
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                {/* </div> */}
                                </>
                            )
                        }))}
                    </Slider>
                    <div className="max-w-3xl mx-auto xl:block justify-center relative hidden ">
                        <div className="flex  justify-center items-center relative lg:absolute bottom-0 right-0 pb-2">
                            <div className="pr-1 py-3 cursor-pointer z-10 w-12 h-auto" onClick={previous} aria-hidden="true">
                                <img
                                    className="h-auto w-full object-cover"
                                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/06/Left-Arrow.png"
                                    alt="left arrow"
                                    loading="lazy"
                                    width={200}
                                    height={97}
                                />
                            </div>
                            <div className="pl-3 py-3 cursor-pointer z-10 w-14 h-auto " onClick={next} aria-hidden="true">
                                <img
                                    className="h-auto w-full object-cover"
                                    src="https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2022/06/Right-Arrow.png"
                                    alt="right arrow"
                                    loading="lazy"
                                    width={200}
                                    height={97}
                                />
                            </div>
                            <div id="arrow-circle" className="w-12 h-12 bg-blue-300 z-0 absolute rounded-full left-0 right-0 top-50% m-auto peer-hover:bg-blue-500">
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-16 flex justify-center">
                    {
                        <div className="inline-flex" data-aos="fade-up">
                            <a
                                href="/testimonials/"
                                className="bg-blue-500 inline-flex relative px-5 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded shadow-lg"
                            >
                            View All Testimonials
                            </a>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}
